import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from "vue-router"
import BootstrapVue3 from 'bootstrap-vue-3'
import "bootstrap/dist/css/bootstrap.css"
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas, fab)
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "main",
            component: ()=> import("./components/pages/main.vue")
        },
        {
            path: "/authen",
            name: "authen",
            component: ()=> import("./components/authenications/Login.vue")
        },
        {
            path: "/policy",
            name: "policy",
            component: ()=> import("./components/pages/policy.vue")
        },
        {
            path: "/focusmarket",
            name: "info",
            component:()=> import("./components/pages/info.vue")
        },
        {
            path: "/support",
            name: "support",
            component:()=> import("./components/pages/supports.vue")
        },
        {
            path: "/request-account",
            name: "delete",
            component:()=> import("./components/pages/deleteRequest.vue")
        },
        {
            path: "/wealth-land",
            name: "home",
            component: ()=> import("./components/pages/home.vue"),
            children: [
                {
                    path: "deshboard",
                    component: ()=> import("./components/pages/contents.vue")
                },
                {
                    path: "asset",
                    component: ()=> import("./components/pages/asset.vue")
                }
            ]
        }
    ]
})
createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(router).use(BootstrapVue3)
.mount('#app')
